<template lang="html">
    <div v-if="dataReady" class="component-wrapper p-2">
        <div v-if="!editorMode" class="playlist-wrapper col-12">
            <div class="row top-line-edit-button">
                <div class="col-9">

                </div>
                <div v-if="isOwner"
                     @click="editorMode=!editorMode"
                     class="col-3 edit-playlist-button">
                        Edit playlist
                </div>
                <div class="col-9">

                </div>
                <div class="col-3 d-flex playlist-image-container">
                    <div class="playlist-image" :style="playlistImageStyle">
                        <p class="playlist-image-text" :style="playlistImageFontStyle">
                            {{playlistInitials}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="search-results-collection-div mt-5">
                <SearchResults v-if="this.playlist.results"
                               :header="playlistName"
                               :searchResultsData="this.playlist"
                               :resultsType="'playlist'"
                               :playlistOwner="owner">
                </SearchResults>
            </div>

        </div>
        <div v-if="editorMode && isOwner">
            <div class="row top-line-edit-button">
                <div class="col-9">

                </div>
                <div @click="editorMode=!editorMode"
                     class="col-3 mb-2 edit-playlist-button cancel">
                    Done
                </div>
                <b-form>

                </b-form>
                <div class="col-md-5 col-12">
                    <b-form method="patch">
                        <b-input class="form-control edit-playlist-name mr-2 mb-2"
                                 placeholder="Playlist name"
                                 aria-label="Search"
                                 v-model="playlistName"
                                 size="lg"
                                 @blur="savePodcastName">
                        </b-input>
                        <b-form-textarea class="form-control create-playlist-input mb-2 mr-2"
                               placeholder="Describe this playlist"
                               aria-label="Podcast summary"
                               v-model="playlistSummary"
                               maxlength="500"
                               @blur="savePodcastSummary">
                        </b-form-textarea>
                        <div class="div-for-dropdown-search">
                            <input class="form-control me-2 mb-2 create-playlist-input"
                                   placeholder="Search for podcasts"
                                   aria-label="Search"
                                   v-model="playlistPodcastSearchString"
                                   :disabled="!playlistId"
                                   v-debounce:600ms="searchForPodcast">
                            <div v-if="playlistPodcastSearchString.length > 0 && playlistPodcastSearchResults.data" class="dropdown-menu playlist-dropdown">
                                <div>
                                    <div @click="addToPlaylist(result)"
                                       class="navbar-single-result-container create-playlist d-flex"
                                       v-for="result in playlistPodcastSearchResults.data.results" :key="result.podcast_id">
                                        <p class="navbar-single-result-name">{{result.name}}</p>
                                        <b-dropdown-divider></b-dropdown-divider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
                <div class="current-playlist col-lg-6 col-md-12">
                    <div v-for="(podcast, index) in playlist.results"
                         class="playlist-podcasts search-result p-3 mb-3"
                         :key="index">
                         <div class="single-podcast-header-text col-9">
                             <p class="results-podcast-name"><strong>{{podcast.name}}</strong></p>
                             <p class="results-podcast-artist">{{podcast.artist}}</p>
                         </div>
                         <div v-if="podcast.podcast_id" id="single-podcast-header-image" class="col-3 d-flex justify-content-end">
                             <b-img rounded fluid class="result-image float-end"
                                    :src="require(`@/assets/img/${podcast.podcast_id}.jpg`)"
                                    @error="$event.target.src('https://cdn.pixabay.com/photo/2020/02/12/05/13/dog-cartoon-4841702_960_720.jpg')">
                             </b-img>
                             <div
                                  class="remove-from-playlist"
                                  @click="removeFromPlaylist(podcast.podcast_id)">
                                 <p>
                                     X
                                 </p>
                             </div>
                         </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchResults from '@/components/search_results/SearchResults.vue'
export default {
    components: {
        SearchResults
    },
    name: 'SinglePlaylist',
    data() {
        return {
            playlistId: window.location.pathname.split('/').pop(),
            playlist: {
                results: []
            },
            dataReady: false,
            playlistName: '',
            owner: '',
            editorMode: false,
            playlistPodcastSearchResults: {},
            playlistPodcastSearchString: '',
            podcastsInPlaylist: {
                'results': []
            },
            showDropDownSearch: true,
            playlistSummary: '',
            base: {
                baseURL: this.$store.state.endpoints.baseUrl,
                headers: {
                    Authorization: `JWT ${this.$store.state.jwt}`,
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true,
                }
            }
        }
    },
    mounted() {
        this.getPlaylist()
        this.createPlaylistImage()
        this.setupUnloadListener()
    },
    computed: {
        isOwner() {
            return this.$store.state.username === this.owner
        },
        podcastsToSave() {
            let postPodcasts = []
            for (let podcast of this.playlist.results) {
                postPodcasts.push({'podcast_id': podcast.podcast_id})
            }
            return postPodcasts
        },
        playlistInitials() {
            let name = this.playlistName.split(' ')
            let wordsToRemove = ['a', 'the', 'of', 'in', 'at', 'but', 'by', 'for', 'to', 'and']
            let filteredName = name.filter(word =>
                                !wordsToRemove.includes(word)
                                && !wordsToRemove.includes(word.toUpperCase())
                                && !wordsToRemove.includes(word.toLowerCase())
                                && !wordsToRemove.includes(word.slice(0,1) + word.slice(1, word.length-1)))
            filteredName = filteredName.join(' ')
            return filteredName.split(' ').map((n)=>n[0].toUpperCase()).join('').slice(0,3)
        },
        playlistImageBackgroundColor() {
            let colors = ['#3b3b3b', '#3c2222', '#222c3c', '#223c2b', '#3a223c']
            let summaryLength = this.playlistSummary.length
            let index = summaryLength % colors.length
            return colors[index]
        },
        playlistImageFontColor() {
            let colors = ['#7afafe', '#fe7a7a', '#84fe7a','#fbfe7a', '#807afe']
            let nameLength = this.playlistName.length
            let index = nameLength % colors.length
            return colors[index]
        },
        playlistImageStyle() {
            return `background:${this.playlistImageBackgroundColor};
                    margin-left: auto;
                    height: 4em;
                    width: 4em;
                    position: absolute;
                    right: 1em;
                    margin-bottom: 2em;
                    margin-top: 1em;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    border-radius: 0.2em;`
        },
        playlistImageFontStyle() {
            return `align-self: center;
                    font-size: 1.5em;
                    font-weight: bolder;
                    cursor: default;
                    color:${this.playlistImageFontColor};`
        }
    },
    watch: {
        podcastsToSave() {
            this.commitAndUpdatePlaylist()
        }
    },
    methods: {
        setupUnloadListener() {
            // window.addEventListener('beforeunload', this.commitAndUpdatePlaylist, true)
            window.addEventListener('visibilitychange', () => {
                // ev.preventDefault()
                this.commitAndUpdatePlaylist()
                return ''
            })
        },
        getPlaylist() {
            this.$http.get(`${this.$store.state.endpoints.baseUrl}playlists/${this.playlistId}`).then((response) => {
                this.playlistName = response.data.name
                document.title = `${this.$store.state.title} - ${this.playlistName}`
                this.playlistSummary = response.data.summary
                this.owner = response.data.user
                this.playlist.results = response.data.podcasts
                this.dataReady = true
            })
        },
        searchForPodcast() {
            let queryUrl = `${this.$store.state.endpoints.baseUrl}podcasts/?name__contains=${this.playlistPodcastSearchString}`
            this.$http.get(queryUrl).then((response) => {
                this.playlistPodcastSearchResults = response
            })
        },
        addToPlaylist(result) {
            if (!this.playlist.results.some(pod => pod.podcast_id === result.podcast_id)) {
                this.playlist.results.unshift(result)
                this.playlistPodcastSearchString = ''
                this.addSinglePodcastToPlaylistDb(result.podcast_id)
            }
        },
        removeFromPlaylist(id) {
            this.playlist.results = this.playlist.results.filter(podcast => podcast.podcast_id !== id)
        },
        addSinglePodcastToPlaylistDb(id) {
            const axiosInstance = this.$http.create(this.base)
            axiosInstance({
                url: `playlists/${this.playlistId}/`,
                method: 'patch',
                data: {'podcasts': [{'podcast_id': id}]},
            }).then((response) => {
                this.playlistId = response.data.id
                // this.editorMode = false
            })
        },
        commitAndUpdatePlaylist() {
            if (this.isOwner) {
                const axiosInstance = this.$http.create(this.base)
                axiosInstance({
                    url: `playlists/${this.playlistId}/`,
                    method: 'patch',
                    data: {'podcasts': [...this.podcastsToSave], 'name': this.playlistName, 'summary': this.playlistSummary},
                }).then((response) => {
                    this.playlistId = response.data.id
                })
            }
        },
        savePodcastName() {
            const axiosInstance = this.$http.create(this.base)
            axiosInstance({
                url: `playlists/${this.playlistId}/`,
                method: 'patch',
                data: {'name': this.playlistName},
            }).then((response) => {
                this.playlistId = response.data.id
            })
        },
        savePodcastSummary() {
            const axiosInstance = this.$http.create(this.base)
            axiosInstance({
                url: `playlists/${this.playlistId}/`,
                method: 'patch',
                data: {'summary': this.playlistSummary},
            }).then((response) => {
                this.playlistId = response.data.id
            })
        },
        createPlaylistImage() {
            let initials = this.playlistName
            return initials
        },
        getPlaylistInitials(playlist) {
            return playlist.name.split(' ').map((n)=>n[0]).join('')
        },
        getPlaylistImageBackgroundColor(playlist) {
            let colors = ['#3b3b3b', '#3c2222', '#222c3c', '#223c2b', '#3a223c']
            let summaryLength = playlist.summary.length
            let index = summaryLength % colors.length
            return colors[index]
        },
        getPlaylistImageFontColor(playlist) {
            let colors = ['#7afafe', '#fe7a7a', '#84fe7a','#fbfe7a', '#807afe']
            let nameLength = playlist.name.length
            let index = nameLength % colors.length
            return colors[index]
        },
        getPlaylistImageStyle(playlist) {
            return `background:${this.getPlaylistImageBackgroundColor(playlist)};
                    margin-left: auto;
                    height: 3em;
                    width: 3em;
                    position: absolute;
                    left: 0em;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    border-radius: 0.2em;`
        },
        getPlaylistImageFontStyle(playlist) {
            return `align-self: center;
                    font-size: 1.5em;
                    color: #84fe7a;
                    font-weight: bolder;
                    cursor: default;
                    color:${this.getPlaylistImageFontColor(playlist)};`
        }
    }
}
</script>

<style lang="css" scoped>
.playlist-wrapper {
    height: 80vh;
    overflow-y: scroll;
    display: inline-block;
    padding: 20px;
    scrollbar-width: none;
}
.playlist-wrapper::-webkit-scrollbar {
    display: none;
}
.edit-playlist-button {
    background: var(--lightBackground);
    color: white;
    border-radius: 1em;
    border: none;
    box-shadow: var(--globalButtonShadow);
    padding: 0.5em;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    cursor: pointer;
}

.edit-playlist-button.cancel {
    background: var(--hoverColor);
}

.edit-playlist-name {
}
.update-playlist-button {
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 1em;
    box-shadow: var(--globalButtonShadow);
    border: none;
    background: var(--hoverColor);
    color: white;
}
.dropdown-menu.playlist-dropdown {
    display: block;
    max-width: 100%;
    color: #333333;
    right: auto;
    left: auto;
    position: absolute;
    top:2.5em;
}
.div-for-dropdown-search {
    position: relative;
}
.playlist-image-container {
    position: relative;
}
.playlist-image {
    margin-left: auto;
    height: 4em;
    width: 4em;
    position: absolute;
    right: 1em;
    margin-bottom: 2em;
    margin-top: 1em;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 0.2em;
}
.playlist-image-text {
    align-self: center;
    font-size: 2em;
    color: #84fe7a;
    font-weight: bolder;
    cursor: default;
}
#playlist-owner {
    margin-left: auto;
    right: 1em;
    top: 5em;
    position: absolute;
}
.results-podcast-name {
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.results-podcast-artist {
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>
