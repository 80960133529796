<template lang="html">
  <div class="component-wrapper">
    <SinglePlaylist></SinglePlaylist>
  </div>
</template>

<script>
import SinglePlaylist from '@/components/playlists/SinglePlaylist.vue'

export default {
    components: {
        SinglePlaylist
    },
    mounted() {
    }
}
</script>

<style lang="css">
</style>
